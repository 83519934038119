/* Basic editor styles */
.ProseMirror > * + * {
    margin-top: 0.75em;
}

.ProseMirror ul,
.ProseMirror ol {
    padding: 0 1rem;
}

.ProseMirror h1 {
    font-size: 140%;
}

.ProseMirror h2 {
    font-size: 120%;
}

.ProseMirror h3,
.ProseMirror h4,
.ProseMirror h5,
.ProseMirror h6 {
    line-height: 1.1;
}

.ProseMirror img {
    max-width: 100%;
    height: auto;
}

.ProseMirror blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(13, 13, 13, 0.1);
}

.ProseMirror hr {
    border: none;
    border-top: 2px solid rgba(117, 117, 117, 0.6);
    margin: 1rem 0;
}

.ProseMirror p.is-editor-empty:first-child::before {
    color: #80808075;
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
}

/* Task List */
ul[data-type="taskList"] {
    list-style: none;
    padding: 0;
}

ul[data-type="taskList"] p {
    margin: 0;
}

ul[data-type="taskList"] li {
    display: flex;
}

ul[data-type="taskList"] li > label {
    flex: 0 0 auto;
    margin-right: 0.5rem;
    user-select: none;
}

ul[data-type="taskList"] li > div {
    flex: 1 1 auto;
}

.header-text {
    font-size: 1.5em;
}

/*noinspection ALL*/
ion-modal#date-modal {
    --width: fit-content;
    --min-width: 250px;
    --height: fit-content;
    --border-radius: 6px;
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
}

/* Apply search bar styles to IonInput */
.title-input-bar {
    --background: #f0ecec; /* Background color */
    --border-radius: 20px; /* Adjust this value to control roundness */
    --padding-start: 16px; /* Left padding */
    --padding-end: 16px; /* Right padding */
    --placeholder-color: #999; /* Placeholder text color */
    --padding-top: -5px;
    --padding-bottom: -5px;
    border: none; /* Remove default border */
}

/* Apply custom styles to the inner input element */
.title-input-bar::part(inner-input) {
    border: none; /* Remove border on inner input */
    background: transparent; /* Set background to transparent */
    padding: 0; /* Remove padding on inner input */
}

.ProseMirror {
    > * + * {
        margin-top: 0.75em;
    }

    .is-empty::before {
        content: attr(data-placeholder);
        float: left;
        color: #adb5bd;
        pointer-events: none;
        height: 0;
    }

    .details {
        display: flex;
        margin: 1rem 0;
        border: 1px solid black;
        border-radius: 0.5rem;
        padding: 0.5rem;

        > button {
            display: flex;
            cursor: pointer;
            background: transparent;
            border: none;
            padding: 0;

            &::before {
                content: '\25B6';
                display: flex;
                justify-content: center;
                align-items: center;
                width: 1.5em;
                height: 1.5em;
            }
        }

        &.is-open > button::before {
            content: '\25BC';
        }

        > div {
            flex: 1 1 auto;
        }

        :last-child {
            margin-bottom: 0;
        }
    }
}

/* Container to help catch clickable content */
.editor-container {
    display: flex;
    flex-direction: column;
    height: 100vh; /* or another fixed height */
}

.EditorContent {
    flex-grow: 0; /* Don't grow beyond content */
    flex-shrink: 1;
}

.clickable-area {
    flex-grow: 1; /* Grow to take up remaining space */
    min-height: 50px; /* Minimum height */
    /* background-color: rgba(0, 0, 0, 0.05);*/
}
