.fab-button-desc::after {
    position: absolute;
    content: attr(data-desc);
    z-index: 9999999999;
    right: 50px;
    bottom: 0;
    background-color: var(--ion-color-light);
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 18px;
    color: #131313;
    font-weight: 500;
    box-shadow: 0 3px 5px -1px rgba(0,0,0,0.1), 0 6px 10px 0 rgba(0,0,0,0.07), 0 1px 18px 0 rgba(0,0,0,0.06);
}
