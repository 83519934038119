/* Style for the login card */
.login-card {
    padding: 10px;
    border-radius: 10px;
}

/* Style for the "Forgot Password?" text */
.forgot-password {
    padding-top: 5px;
    text-align: right;
    font-size: small;
}

.small-text {
    font-size: smaller;
}

/* Style for the line separator and "OR" text */
.line-container {
    display: flex;
    align-items: center;
    /*margin-top: 0px;*/
}

.line {
    flex-grow: 1;
    height: 1px;
    background-color: #ccc;
    margin: 0 10px;
}

/* Style for the "Continue with Google" button */
.google-button {
    --ion-color-primary: #4285f4; /* Google blue */
    --ion-color-primary-shade: #2a58a8; /* Shade (Darker) */
    --ion-color-primary-tint: #6a9cff; /* Tint (Lighter) */
    margin: 0 auto;
}


.google-label {
    color: #fff;
}
