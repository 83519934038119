/* Styles for your IonImg */
.toolbar-logo {
    width: 40px;
    height: 30px;
    max-height: 100%;
    padding-left: 5px;
}

/* Media query for mobile devices (max-width: 768px) */
/*
@media (max-width: 768px) {
    .toolbar-logo {
        padding-left: 0;
    }
}
*/

/* Styles for your IonTitle */
.toolbar-title {
    font-family: 'Roboto', sans-serif;
    font-weight: 200;
    font-size: 1.3rem;
    letter-spacing: 0.6px;
    text-transform: lowercase;
    color: #333;
    margin-left: -8px;
}

/* Media query to hide title on mobile devices */
/*
@media (max-width: 600px) {
    .toolbar-title {
        display: none;
    }
}
 */
