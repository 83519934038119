.connection-content {
    --background: #efeff4;
}

.connection-card {
    box-shadow: 0px 0px 0px;
}

.color-button {
    --box-shadow: none;
}

.centered-thumbnail {
    display: flex;
    justify-content: center; /* Horizontally center */
    align-items: center; /* Vertically center */
}

.small-thumbnail {
    --size: 20%;
}

/* Sets up label text stacked on one another */
.label-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
