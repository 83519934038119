body {
    margin: 0;
    padding: 50px;
    font: normal 18px/1.4 Arial, sans-serif;
}

.picker {
    width: 300px;
    background: #f8f8f8; /* #f0f0f4; */
    border-radius: 9px;
}

.picker .react-colorful {
    width: auto;
}

.picker__swatches {
    display: flex;
    padding: 12px;
    flex-wrap: wrap;
}

.picker__swatch {
    width: 24px;
    height: 24px;
    margin: 4px;
    padding: 0;
    border-radius: 4px;
    cursor: pointer;
    outline: none;
    border: 1px solid #cbcbcb;
}
