.toolbar-link {
    display: flex; /* Ensures that children are in a row */
    align-items: center; /* Aligns children vertically in the center */
    text-decoration: none; /* Removes underline from the link */
}

.tagline-container h2 {
    text-align: center; /* Center aligns the text */
    font-weight: bold; /* Adds weight to the font */
    font-size: 1.5em; /* Adjust the size as needed */
    margin: 20px 0; /* Adds some space above and below */
    color: #333; /* Set the text color, change as needed */
    line-height: 1.4; /* Adjusts the space between lines of text */
    /* You can add more styling like font-family, letter-spacing, etc., as per your design */
}

.grid-image {
    width: 100%;
    border-radius: 5px;
    object-fit: contain;
    border: 1px solid #5757577F;
}

.grid-content {
    border-radius: 20px;
    background-color: rgba(255, 255, 255, 0.5);
    padding-Left: 20px;
}

.grid-container {
    max-width: 1000px;
    width: 90%;
    justify-content: center;
    align-items: center;
    display: flex;
}
