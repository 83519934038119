/* https://dev.to/fm3ndoza/styling-ion-tabs-in-ionic-5-clm */

/*noinspection ALL*/
ion-tab-bar {
    bottom: 2px;
    position: relative;
    border-radius: 16px;
    width: 94%;
    margin: 0 auto;
}

/*noinspection ALL*/
ion-icon.large-tab-button {
    font-size: 50px !important;
}

/*noinspection ALL*/
ion-icon.create-option {
    margin-top: 6px;
}

/*noinspection ALL*/
ion-text.create-option {
    font-weight: bolder;
}