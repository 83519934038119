/* Style for the container */
.line-container {
    padding-top: 10px;
    padding-bottom: 0;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
}

/* Style for the line */
.line {
    flex-grow: 1;
    border-top: 1px solid #cccccc;
    max-width: 30%;
}

/* Style for the text on either side */
.line-container .text {
    margin: 0 10px;
}

.login-oauth-card {
    border: none !important; /* Remove borders */
    box-shadow: none !important; /* Remove shadows */
    margin: 0 auto;
    max-width: 800px;
}