ion-segment-button.ios::part(native) {
    /* color: #08a391; */
    color: gray;
}

.segment-button-checked.ios::part(native) {
    color: black;
}

ion-segment-button.ios::part(indicator-background) {
    border-radius: 20px;
}

/* Define styles for the gallery grid */
.gallery-grid {
    /* padding: 16px;*/ /* Add some padding around the grid */
}

/* Style the cards within the grid */
ion-card {
    margin-left: -1px;
    margin-right: -1px;
    margin-bottom: -10px; /* Add some space between cards */
}

ion-card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.media-container {
    height:400px;
    overflow: hidden;
}

@media (max-width: 768px) {
    .media-container {
        height: 150px;
    }
}


/* Adjust the layout for larger screens */

/*@media (min-width: 768px) {*/
/*    .gallery-grid {*/
/*        max-width: 900px; !* Limit the width of the grid on larger screens *!*/
/*        margin: 0 auto; !* Center the grid horizontally *!*/
/*    }*/
/*}*/
