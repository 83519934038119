.logo-image {
    width: 5em;
    height: 5em;
    margin: auto;
    display: block;
}

.logo-text {
    font-family: 'Roboto', sans-serif;
    font-weight: 200;
    font-size: 1.3rem;
    letter-spacing: 0.6px;
    text-transform: lowercase;
    color: #333;
}

